<template>
  <div class="container">
    <a-row>
      <a-col :span="23" class="right">
        <div class="right-content">
          <a-spin tip="Loading..." :spinning="spinning">
            <a-form :form="form">
              <a-form-item label="规格" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-table :columns="columns" :data-source="data" :pagination="false" :bordered="true" :loading="loading">
                  <div slot="check" slot-scope="text, record, index">
                    <div style="display: flex">
                      <div
                        v-for="(item, cIndex) in record.goodsSpecValueSkus"
                        :key="cIndex"
                        :class="['checkebox-item', item.checked ? 'checkbox-checked' : '']"
                        @click="chooseCheckboxItem(item, index, cIndex)"
                      >
                        {{ item.specValue }}
                      </div>
                    </div>
                  </div>
                </a-table>
              </a-form-item>
            </a-form>
            <a-form :form="form">
              <a-form-item label="sku表" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-table
                  :columns="heads"
                  :data-source="skuData"
                  :pagination="false"
                  :bordered="true"
                  :loading="loading"
                >
                  <span slot="minPrice" slot-scope="text, record">
                    <a-input-number
                      :min="0"
                      :max="9999999"
                      :precision="2"
                      v-model="record.minPrice"
                      placeholder="请输入"
                    />
                  </span>
                  <span slot="costPrice" slot-scope="text, record">
                    <a-input-number
                      :min="0"
                      :max="9999999"
                      :precision="2"
                      v-model="record.costPrice"
                      placeholder="请输入"
                    />
                  </span>
                  <span slot="commissionRatio" slot-scope="text, record">
                    <a-input-number
                      :min="0"
                      :max="9999999"
                      :precision="2"
                      :step="0.1"
                      v-model="record.commissionRatio"
                      placeholder="请输入"
                    />
                  </span>
                  <span slot="spePrice" slot-scope="text, record">
                    <a-input-number
                      v-model="record.price"
                      :min="0"
                      :max="9999999"
                      :precision="2"
                      placeholder="请输入价格"
                      style="width: 120px"
                    />
                  </span>
                  <span slot="specStock" slot-scope="text, record">
                    <a-input-number
                      v-model="record.stock"
                      placeholder="请输入库存"
                      :min="0"
                      :max="9999999"
                      :precision="0"
                      style="width: 120px"
                    />
                  </span>
                  <span slot="status" slot-scope="text, record">
                    <a-switch checked-children="启用" un-checked-children="禁用" v-model="record.checked"/>
                  </span>
                  <span slot-scope="text, record, index" slot="speImage">
                    <div style="margin: auto; width: 104px; height: 104px" @click="chooseSpeImage(index)">
                      <a-upload
                        style="margin: auto"
                        name="avatar"
                        accept=".png,.jpg,jpeg,.gif"
                        list-type="picture-card"
                        :show-upload-list="false"
                        :customRequest="customUserRequest"
                      >
                        <img
                          :src="record.image"
                          v-if="record.image"
                          style="width: 94px; height: 94px; margin-top: 3px"
                        />
                        <div v-else>
                          <a-icon type="plus"/>
                          <div class="ant-upload-text">上传图片</div>
                        </div>
                      </a-upload>
                    </div>
                  </span>
                </a-table>
              </a-form-item>
            </a-form>
          </a-spin>
        </div>
        <div style="display: flex; justify-content: space-between; padding-bottom: 20px">
          <div></div>
          <div>
            <a-button type="success" @click="backToPrevPage">返回</a-button>
            <a-button type="primary" @click="saveSkuData" style="margin-left: 20px">保存</a-button>
          </div>
          <div></div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {getSkuListByGoodsId, addSkuData} from '@/api/modular/mallLiving/commodity/commodityList'
import {uploadFile} from "@/api/modular/mallLiving/uploadFile";

export default {
  data() {
    const vm = this

    return {
      value: [],
      form: this.$form.createForm(this),
      videoLoading: false,
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 2,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 22,
        },
      },
      speCurrentIndex: 0,
      data: [],
      heads: [],
      skuData: [],
      columns: [
        {
          title: '规格名',
          dataIndex: 'specName',
          align: 'center',
          width: 50,
        },
        {
          title: '规格值',
          dataIndex: 'checkList',
          align: 'center',
          width: 200,
          scopedSlots: {
            customRender: 'check',
          },
        },
      ],
      confirmLoading: false,
      statusList: [],
      queryParam: {},
      title: '',
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      // 弹出层
      visible: false,
      standardrequest: false,
      detailShow: false,
      Treetype: 'top', // top center bottom
      // 加载状态
      spinning: false,
      expandedKeys: [0],
      // 多级联动
      checkId: '',
      loading: false,
      currentItem: {},
      currentOrgName: '',
      goodsId: '',
      type: '',
      skuDataList: [],
      position: '',
      cos: null,
    }
  },
  props: [],
  computed: {},
  filters: {},
  mounted() {
    this.goodsId = this.$route.query.id
    this.initSpeValueTableHeader()
  },
  methods: {
    customUserRequest(filedata) {
      this.videoLoading = true
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          const percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          const list = res.res.requestUrls[0].split('?');
          if (list.length > 0) {
            this.skuData[this.speCurrentIndex].image = list[0]
            this.videoLoading = false
          }
        }
      })
    },
    chooseSpeImage(index) {
      this.speCurrentIndex = index
    },

    saveSkuData() {
      let that = this
      var list = []
      console.log('data', this.skuData)
      this.skuData.forEach((item) => {
        if (item.price && item.image && item.stock) {
          list.push({
            goodsId: that.goodsId,
            goodsSpecValue: item.goodsSpecValue,
            image: item.image,
            price: item.price,
            minPrice: item.minPrice,
            costPrice: item.costPrice,
            commissionRatio: item.commissionRatio,
            stock: item.stock,
            delFlag: item.checked ? 1 : 3,
            id: item.id,
          })
        }
      })
      var params = {
        goodsId: that.goodsId,
        skuList: list,
      }
      addSkuData(params).then((res) => {
        let that = this
        if (res.success) {
          that.$message.success('新增成功')
          setTimeout(function () {
            that.backToPrevPage()
          }, 250)
        }
      })
    },

    backToPrevPage() {
      this.$router.push({
        path: '/commodityitems',
      })
    },

    initSpeValueTableHeader() {
      let that = this
      getSkuListByGoodsId({
        id: that.goodsId,
      }).then((res) => {
        if (res.success) {
          if (res.data && res.data.speList) {
            that.data = res.data.speList
            that.data.forEach((item) => {
              item.checked = true
              item.goodsSpecValueSkus.forEach((cItem) => {
                cItem.checked = true
              })
            })
            that.data = JSON.parse(JSON.stringify(that.data))
            that.skuDataList = res.data.skuList.filter((item) => {
              return item && item.id
            })
            console.log('skuDataList', that.skuDataList)
            that.initSkuData()
          }
        }
      })
    },
    initSkuData() {
      this.data.forEach((item) => {
        var list = item.goodsSpecValueSkus.filter((citem) => {
          return citem.checked
        })
        item.checked = list.length > 0
      })
      this.initHeadTitle()
      this.initSepValueTableData()
    },
    initHeadTitle() {
      var headsList = []
      var list = this.data.filter((item) => {
        return item.checked
      })
      list.forEach((item, index) => {
        headsList.push({
          title: item.specName,
          dataIndex: 'labelValue' + index,
          align: 'center',
          width: 120,
        })
      })
      if (headsList.length > 0) {
        headsList.push(
          {
            title: '规格图片',
            dataIndex: 'image',
            align: 'center',
            width: 80,
            scopedSlots: {
              customRender: 'speImage',
            },
          },
          {
            title: '价格',
            dataIndex: 'price',
            align: 'center',
            width: 250,
            scopedSlots: {
              customRender: 'spePrice',
            },
          },
          {
            title: '业务员可调整的最低价',
            dataIndex: 'minPrice',
            key: 'minPrice',
            scopedSlots: {
              customRender: 'minPrice',
            },
          },
          {
            title: '成本价',
            dataIndex: 'costPrice"',
            key: 'costPrice',
            scopedSlots: {
              customRender: 'costPrice',
            },
          },
          {
            title: '业务员卖出平台的商品，利润拿出去和代理商分的比例',
            dataIndex: 'commissionRatio',
            key: 'commissionRatio',
            scopedSlots: {
              customRender: 'commissionRatio',
            },
          },
          {
            title: '库存',
            dataIndex: 'stock',
            align: 'center',
            width: 250,
            scopedSlots: {
              customRender: 'specStock',
            },
          },
          {
            title: '状态',
            dataIndex: 'delFlag',
            align: 'center',
            width: 150,
            scopedSlots: {
              customRender: 'status',
            },
          }
        )
      }
      this.heads = headsList
    },

    initSepValueTableData() {
      let that = this
      var list = this.data.filter((item) => {
        return item.checked
      })
      var childList = []
      var sum = 1
      list.forEach((item) => {
        var cList = item.goodsSpecValueSkus.filter((cItem) => {
          return cItem.checked
        })
        childList.push(cList)
        sum *= cList.length
      })
      that.skuData = []
      for (var i = 0; i < sum; i++) {
        that.skuData.push({
          image: '',
          id: null,
          price: '',
          status: 0,
          stock: '',
        })
      }
      that.skuData.forEach((item, index) => {
        childList.forEach((element, aIndex) => {
          item['labelValue' + aIndex] = ''
          item['labelId' + aIndex] = ''
        })
      })
      var newArr = this.doExchange(childList)
      if (newArr.length > 0 && newArr[0].goodsSpecValueId) {
        newArr.forEach((item, index) => {
          that.skuData[index]['labelValue0'] = item.specValue
          that.skuData[index]['labelId0'] = item.goodsSpecValueId
          var ids = item.goodsSpecValueId
          that.skuData[index].goodsSpecValue = ids
          that.skuData[index].goodsId = this.goodsId
          that.skuData[index].delFlag = 1
          that.compareWithTwos()
        })
      } else {
        newArr.forEach((item, index) => {
          var ids = ''
          item.forEach((citem, cindex) => {
            that.skuData[index]['labelValue' + cindex] = citem.specValue
            that.skuData[index]['labelId' + cindex] = citem.goodsSpecValueId
            ids += citem.goodsSpecValueId + (cindex != item.length - 1 ? ',' : '')
          })
          that.skuData[index].goodsSpecValue = ids
          that.skuData[index].goodsId = this.goodsId
          that.skuData[index].delFlag = 1
          that.compareWithTwos()
        })
      }
    },

    compareWithTwos() {
      this.skuData.forEach((item) => {
        this.skuDataList.forEach((citem) => {
          if (item.goodsSpecValue === citem.goodsSpecValueStr) {
            item.image = citem.image
            item.price = citem.price
            item.minPrice = citem.minPrice
            item.costPrice = citem.costPrice
            item.commissionRatio = citem.commissionRatio
            item.stock = citem.stock
            item.id = citem.id
            item.delFlag = citem.delFlag
            return
          }
        })
      })
      this.skuData.forEach((element) => {
        element.checked = element.delFlag == 1
      })
      this.skuData = JSON.parse(JSON.stringify(this.skuData))
    },

    doExchange(arr) {
      let that = this
      var len = arr.length
      // 当数组大于等于2个的时候
      if (len >= 2) {
        // 第一个数组的长度
        var len1 = arr[0].length
        // 第二个数组的长度
        var len2 = arr[1].length
        // 2个数组产生的组合数
        var lenBoth = len1 * len2
        //  申明一个新数组
        var items = new Array(lenBoth)
        // 申明新数组的索引
        var index = 0
        for (var i = 0; i < len1; i++) {
          for (var j = 0; j < len2; j++) {
            if (arr[0][i] instanceof Array) {
              items[index] = arr[0][i].concat(arr[1][j])
            } else {
              items[index] = [arr[0][i]].concat(arr[1][j])
            }
            index++
          }
        }
        var newArr = new Array(len - 1)
        for (var i = 2; i < arr.length; i++) {
          newArr[i - 1] = arr[i]
        }
        newArr[0] = items
        return that.doExchange(newArr)
      } else {
        return arr[0]
      }
    },
    showModal() {
      this.visible = true
    },
    handleOk(e) {
      this.ModalText = 'The modal will be closed after two seconds'
      this.confirmLoading = true
      setTimeout(() => {
        this.visible = false
        this.confirmLoading = false
      }, 2000)
    },
    handleCancel(e) {
      this.visible = false
    },
    chooseCheckboxItem(item, index, cIndex) {
      // this.data[index].goodsSpecValueSkus[cIndex].checked = !item.checked
      // this.initSkuData()
    },
  },
}
</script>

<style lang="less" scoped>
// @import url(); 引入公共css
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 20px;
  height: 20px;
}

.checkebox-item {
  padding: 5px 10px;
  line-height: 22px;
  margin: 0 18px 10px 0;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #ddd;
  background-color: #ddd;
  color: #333;
}

.checkbox-checked {
  color: #fff;
  background-color: #43a73c;
}
</style>
